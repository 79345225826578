import React from "react"
import { MyISNImage } from "../gatsby-images/myISNImage"
import { MyISNImagetwo } from "../gatsby-images/myISNImagetwo"
import { IMTImage } from "../gatsby-images/imtImage"
import { DesignSystemImage } from "../gatsby-images/designSystemImage"
import { IMTcImage } from "../gatsby-images/imtcImage"
import { IMTcImagetwo } from "../gatsby-images/imtcImagetwo"
import { IMTImagetwo } from "../gatsby-images/imtImagetwo"
import { RightArrow } from "../../icons/icons"
import UseAnimations from "react-useanimations";
import arrowRightCircle from 'react-useanimations/lib/arrowRightCircle'

const ISN = () => {
  return (
    <div className="projects">
      <ProjectHeader />
      <ThreeDeviceShowcase />
      <ProjectDetails />
      <SingleShowcase />
      <TwoDeviceShowcase left={3} right={4} />
      <TwoDeviceShowcase left={5} right={6} flip={true} />
      <ProjectDetailstwo />
      <SingleShowcaseDStwo />
      <ProjectHeadertr />
      <ThreeDeviceShowcasetwo />
      <SingleShowcasetwo />
      <ProjectDetailsDesignSystemtwo />
      <SingleShowcaseDSfour />
      <ProjectDetailsDesignSystemthree />
      <SingleShowcaseDS1 />
      <ProjectDetailsDesignSystemthreetwo/>
      <SingleShowcaseDSthree />
      <ProjectDetailsDesignSystem />
      <SingleShowcaseDS />
      <Dribbble />
    </div>
  )
}

const ProjectHeader = () => {
 
  return (
    <div className="project-header">
      <div className="container">
        <div className="project-inner">
          <div className="project-title">
            <h5>Speedoc PTE, LTD.</h5>
          </div>
          <div className="project-description">
            <p>
            I designed and maintained the visual integrity of speedoc's digital assets. I conducted UI/UX requirement gatherings, analysis, design.
            I Strived to understand conversational interactions to collaborate with product management and engineering to define and implement interaction solutions for product refinement and an overall rich experience.
            I Developed user flows, UI specs and mock-ups. 
            </p>
            </div>
          
        </div>
      </div>
    </div>
  )
}

const ProjectHeadertr = () => {
 
  return (
    <div className="project-header">
      <div className="container">
        <div className="project-inner">
          <div className="project-title">
            <h5>Shopigroup</h5>
          </div>
          <div className="project-description">
            <p>
            With a product that is used everyday, not having a design system is an absolute nightmare. I've been working to establish a design system and cloud infrastructure. 
            </p>
            </div>
          
        </div>
      </div>
    </div>
  )
}


const ProjectHeadertwo = () => {
 
  return (
    <div className="project-header">
      <div className="container">
        <div className="project-inner">
          <div className="project-title">
            <h5>Speedoc PTE, LTD.</h5>
          </div>
          <div className="project-description">
            <p>
            I Conceptualised original user flows that bring simplicity and user-friendliness to complex UX roadblocks.
            I Created engaging design prototypes, micro-interactions and transitions.
            I Conducted usability tests.Mapping out the different user journeys in more detail, Storytelling chops, bringing these user stories and insights to life.
             </p>
            </div>
         
        </div>
      </div>
    </div>
  )
}

const ProjectDetails = () => {
  return (
    <div className="project-header details">
      <div className="container">
        <div className="project-inner">
          <div className="project-title">
            <h5>The Future of healthcare</h5>
          </div>
          <div className="project-description">
            <p>
            I Conceptualised original user flows that bring simplicity and user-friendliness to complex UX roadblocks.
            I Created engaging design prototypes, micro-interactions and transitions.
            I Conducted usability tests.Mapping out the different user journeys in more detail, Storytelling chops, bringing these user stories and insights to life.
       
          </p>
          </div>
          
        </div>
      </div>
    </div>
  )
}


const ProjectDetailstwo = () => {
  return (
    <div className="project-header details">
      <div className="container">
        <div className="project-inner">
          <div className="project-title">
            <h5>We make it easy to be healthy</h5>
          </div>
          <div className="project-description">
            <p>
            I was always keeping abreast of industry trends, and supported the team in learning. I presented insights and rallied the team to co-create solutions, leading by example.
            I drove by data and UX research, designing and analyzing interactions to support key growth metrics, defining targets.
            I asked targeted questions that will allowed to gather critical data, craft solutions and deliver validated design solutions to our internal teams, and country level partners.
            I presented and defended designs and key deliverables to the team referencing user psychology, concept and usability testing and interaction models in doing so. Willing to jump into projects solutions with ambiguous details in multiple domains. 
            </p>
          </div>
          
        </div>
      </div>
    </div>
  )
}





const ProjectDetailsDesignSystem = () => {
  return (
    <div className="project-header details">
      <div className="container">
        <div className="project-inner">
          <div className="project-title">
            <h5>GUIO Y COMPANIA LTDA</h5>
          </div>
          <div className="project-description">
            <p>
            I designed and maintained visual integrity of digital assets for companies in Colombia and Panama.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const ProjectDetailsDesignSystemthree = () => {
  return (
    <div className="project-header details">
      <div className="container">
        <div className="project-inner">
          <div className="project-title">
            <h5>Confidentiel</h5>
          </div>
          <div className="project-description">
            <p>
            Case study to propose value to investors for cruise company during this COVID-19 pandemics.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const ProjectDetailsDesignSystemthreetwo = () => {
  return (
    <div className="project-header details">
      <div className="container">
        <div className="project-inner">
          <div className="project-title">
            <h5>KSH, Sas.</h5>
          </div>
          <div className="project-description">
            <p>
            Case study to propose value to investors to get started.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const ProjectDetailsDesignSystemtwo = () => {
  return (
    <div className="project-header details">
      <div className="container">
        <div className="project-inner">
          <div className="project-title">
            <h5>Sports application | Confidentiel</h5>
          </div>
          <div className="project-description">
            <p>
            Rebranding UX to offer the best user experience and stand for better investment income.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const ThreeDeviceShowcase = ({ flip }) => {
  return (
    <div className="three-device-showcase">
      <div className="container">
        <div className="three-device-showcase-inner">
          <div className="device">
            <MyISNImage id={1} />
          </div>
          <div className="device">
            <MyISNImage id={2} />
          </div>
          <div className="device">
            <MyISNImage id={3} />
          </div>
        </div>
        <span className="subtitle">
        
        </span>
      </div>
      <span className={`background-box ${flip && "left"}`}></span>
    </div>
  )
}




const ThreeDeviceShowcasetwo = ({ flip }) => {
  return (
    <div className="three-device-showcase">
      <div className="container">
        <div className="three-device-showcase-inner">
          <div className="device">
            <MyISNImagetwo id={1} />
          </div>
          <div className="device">
            <MyISNImagetwo id={2} />
          </div>
          <div className="device">
            <MyISNImagetwo id={3} />
          </div>
        </div>
        <span className="subtitle">
       
        </span>
      </div>
      <span className={`background-box ${flip && "left"}`}></span>
    </div>
  )
}
const TwoDeviceShowcase = ({ left, right, flip }) => {
  return (
    <div className="two-device-showcase">
      <div className="container">
        <div className="two-device-showcase-inner">
          <div className="device">
            <IMTImage id={left} />
          </div>
          <div className="device">
            <IMTImage id={right} />
          </div>
        </div>
      </div>
      <span className={`background-box ${flip && "left"}`}></span>
    </div>
  )
}

const SingleShowcase = () => {
  return (
    <div className="single-showcase">
      <div className="container">
        <div className="single-showcase-inner">
          <div className="device">
            <IMTImage id={1} />
            <span className="subtitle">
             
            </span>
          </div>
          <div className="device">
            <IMTImage id={2} />
            <span className="subtitle">
              
            </span>
          </div>
          <div className="device">
            <IMTcImage id={1} />
            <span className="subtitle">
             
            </span>
          </div>
          <div className="device">
            <IMTcImage id={2} />
            <span className="subtitle">
              
            </span>
          </div>
          <div className="device">
            <IMTcImage id={3} />
            <span className="subtitle">
              
            </span>
          </div>
          <div className="device">
            <IMTcImage id={4} />
            <span className="subtitle">
    
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}


const SingleShowcasetwo = () => {
  return (
    <div className="single-showcase">
      <div className="container">
        <div className="single-showcase-inner">
          <div className="device">
            <IMTImagetwo id={1} />
            <span className="subtitle">
                        </span>
          </div>
          <div className="device">
            <IMTImagetwo id={2} />
            <span className="subtitle">
                 </span>
          </div>
          <div className="device">
            <IMTcImagetwo id={1} />
            <span className="subtitle">
           
            </span>
          </div>
          <div className="device">
            <IMTcImagetwo id={2} />
            <span className="subtitle">
           
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}



const SingleShowcaseDS = () => {
  return (
    <div className="single-showcase">
      <div className="container">
        <div className="single-showcase-inner">
          <div className="device">
            <DesignSystemImage id={4} />
            <span className="subtitle">
         
            </span>
          </div>
          <div className="device">
            <DesignSystemImage id={5} />
            <span className="subtitle">
                     </span>
          </div>
        </div>
      </div>
    </div>
  )
}


const SingleShowcaseDS1 = () => {
  return (
    <div className="single-showcase">
      <div className="container">
        <div className="single-showcase-inner">
          <div className="device">
            <DesignSystemImage id={1} />
            <span className="subtitle">
            
            </span>
          </div>
         
        </div>
      </div>
    </div>
  )
}

const SingleShowcaseDStwo = () => {
  return (
    <div className="single-showcase">
      <div className="container">
        <div className="single-showcase-inner">
          <div className="device">
            <DesignSystemImage id={2} />
            <span className="subtitle">
           
            </span>
          </div>
         
        </div>
      </div>
    </div>
  )
}
const SingleShowcaseDSthree = () => {
  return (
    <div className="single-showcase">
      <div className="container">
        <div className="single-showcase-inner">
          <div className="device">
            <DesignSystemImage id={3} />
            <span className="subtitle">
            
            </span>
          </div>
         
        </div>
      </div>
    </div>
  )
}

const SingleShowcaseDSfour = () => {
  return (
    <div className="single-showcase">
      <div className="container">
        <div className="single-showcase-inner">
          <div className="device">
            <DesignSystemImage id={6} />
            <span className="subtitle">
             </span>
          </div>
         
        </div>
      </div>
    </div>
  )
}

const Dribbble = () => {
  return (
    <div className="dribble-showcase">
      <div className="container">
        <div className="dribble-showcase-inner">
          <h4>Let's talk</h4>
          <span>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="mailto:raphael@paaolms.com"
              className="drib"
            >
            
            Contact Me
          
           <UseAnimations animation={arrowRightCircle} size={32} autoplay={true} loop={true} speed={0.2} strokeColor="#F2F3F4"style={{}} />

            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default ISN
